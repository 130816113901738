var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content-body pb-8"
  }, [_c('div', {
    staticClass: "space-y-7"
  }, [_c('div', {
    staticClass: "flex justify-between items-center mb-6"
  }, [_c('h1', {
    staticClass: "text-3xl font-bold"
  }, [_vm._v("Leave Balance")]), _c('Button', {
    attrs: {
      "buttonText": "Download"
    },
    on: {
      "action": function action($event) {
        return _vm.toggleDownloadConfirmation();
      }
    }
  })], 1), _c('div', {
    staticClass: "shadow-small card pt-4 pb-8 bg-white rounded-lg px-6 flex justify-between"
  }, [_c('div', {
    staticClass: "flex-1 flex justify-start items-end"
  }, [_c('div', {
    staticClass: "flex gap-2 items-end"
  }, [_c('div', {
    staticClass: "w-96"
  }, [_c('label', {
    staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3"
  }, [_vm._v("Search Leave")]), _c('div', {
    staticClass: "relative"
  }, [_c('div', {
    staticClass: "absolute left-4 top-1/2 transform -translate-y-1/2"
  }, [_c('Search', {
    attrs: {
      "color": "neutral-400"
    }
  })], 1), _c('TextField', {
    attrs: {
      "type": "text",
      "withIcon": "",
      "borderEnabled": "",
      "placeholder": "Search by employee name",
      "enterKeyAction": _vm.doSearch
    },
    model: {
      value: _vm.keyword,
      callback: function callback($$v) {
        _vm.keyword = $$v;
      },
      expression: "keyword"
    }
  })], 1)]), _c('div', {
    staticClass: "flex gap-5"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Search",
      "type": "secondary",
      "size": "regular",
      "additionalClass": "px-6 py-3"
    },
    on: {
      "action": function action($event) {
        return _vm.doSearch();
      }
    }
  })], 1)])])]), _vm.stillLoading ? _c('div', {
    staticClass: "loading-page"
  }, [_c('vue-simple-spinner', {
    attrs: {
      "size": "large",
      "message": "Fetching Data ..."
    }
  })], 1) : _vm._e(), _vm.leaveBalanceList.length > 0 && !_vm.stillLoading ? _c('div', [_c('LeaveTable', {
    attrs: {
      "columns": _vm.columnsTable,
      "list": _vm.leaveBalanceList,
      "compact": ""
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(_ref) {
        var entry = _ref.entry,
            column = _ref.column;
        return [column.label === 'Actions' ? _c('div', {
          staticClass: "flex justify-end gap-3 mr-4"
        }, [_c('Button', {
          attrs: {
            "type": "tertiary",
            "size": "icon",
            "forIcon": {
              color: 'yellow'
            },
            "icon": function icon() {
              return import(
              /* webpackChunkName: 'icon' */
              '@/components/Icons/Eye');
            },
            "tooltip": {
              show: true,
              text: 'Detail'
            }
          },
          on: {
            "action": function action($event) {
              return _vm.toLeaveBalanceDetail(entry.employeeId);
            }
          }
        })], 1) : column.label === 'Employee' || column.label === 'Department' ? _c('div', {
          staticClass: "min-w-max"
        }, [_vm._v(_vm._s(entry[column.field]))]) : _c('div', [_vm._v(_vm._s(entry[column.field]))])];
      }
    }], null, false, 2439976009)
  }), _vm.totalLeaveBalance > _vm.sizePerPage ? _c('div', {
    staticClass: "mt-3 flex justify-center items-center"
  }, [_c('Pagination', {
    attrs: {
      "currentPage": _vm.currentPage,
      "totalCount": _vm.totalLeaveBalance,
      "pageSize": _vm.sizePerPage
    },
    on: {
      "onPageChange": _vm.onPageChange
    }
  })], 1) : _vm._e()], 1) : _c('div', {
    staticClass: "text-center mt-10"
  }, [_c('img', {
    staticClass: "mx-auto mt-28",
    attrs: {
      "src": require("@/assets/images/empty-content.svg"),
      "alt": "empty"
    }
  }), _c('div', {
    staticClass: "font-medium text-xl py-5"
  }, [_vm._v(_vm._s(_vm.emptyText))])])]), _c('Modal', {
    attrs: {
      "modalVisible": _vm.downloadConfirmationVisible
    },
    on: {
      "close": function close($event) {
        return _vm.toggleDownloadConfirmation();
      }
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('div', {
    staticClass: "text-left my-2"
  }, [_c('h1', {
    staticClass: "text-xl font-bold mb-3"
  }, [_vm._v("Donwload Confirmation")]), _c('p', {
    staticClass: "text-sm mb-4"
  }, [_vm._v("Are you sure you want to download? Please ensure this download is within the allowed limit of 250 entries only.")]), _c('div', {
    staticClass: "flex justify-end items-center gap-4"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Cancel",
      "type": "tertiary",
      "additionalClass": "px-10"
    },
    on: {
      "action": function action($event) {
        return _vm.toggleDownloadConfirmation();
      }
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "Download",
      "type": "primary"
    },
    on: {
      "action": function action($event) {
        return _vm.downloadData();
      }
    }
  })], 1)])])], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }