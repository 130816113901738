<template>
  <div class="content-body pb-8">
    <div class="space-y-7">
      <div class="flex justify-between items-center mb-6">
        <h1 class="text-3xl font-bold">Leave Balance</h1>
        <Button buttonText="Download" @action="toggleDownloadConfirmation()" />
      </div>
      <div class="shadow-small card pt-4 pb-8 bg-white rounded-lg px-6 flex justify-between">
        <div class="flex-1 flex justify-start items-end">
          <div class="flex gap-2 items-end">
            <div class="w-96">
              <label class="text-neutral-500 text-xs font-medium mb-1 pl-3">Search Leave</label>
              <div class="relative">
                <div class="absolute left-4 top-1/2 transform -translate-y-1/2">
                  <Search color="neutral-400" />
                </div>
                <TextField type="text" withIcon borderEnabled placeholder="Search by employee name" :enterKeyAction="doSearch" v-model="keyword" />
              </div>
            </div>
            <div class="flex gap-5">
              <Button buttonText="Search" type="secondary" size="regular" additionalClass="px-6 py-3" @action="doSearch()" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="stillLoading" class="loading-page">
        <vue-simple-spinner size="large" message="Fetching Data ..."></vue-simple-spinner>
      </div>
      <div v-if="leaveBalanceList.length > 0 && !stillLoading">
        <LeaveTable :columns="columnsTable" :list="leaveBalanceList" compact>
          <template slot="table-row" slot-scope="{ entry, column }">
            <div v-if="column.label === 'Actions'" class="flex justify-end gap-3 mr-4">
              <Button
                type="tertiary"
                size="icon"
                :forIcon="{ color: 'yellow' }"
                :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Eye')"
                :tooltip="{ show: true, text: 'Detail' }"
                @action="toLeaveBalanceDetail(entry.employeeId)"
              />
            </div>
            <div v-else-if="column.label === 'Employee' || column.label === 'Department'" class="min-w-max">{{ entry[column.field] }}</div>
            <div v-else>{{ entry[column.field] }}</div>
          </template>
        </LeaveTable>
        <div v-if="totalLeaveBalance > sizePerPage" class="mt-3 flex justify-center items-center">
          <Pagination :currentPage="currentPage" :totalCount="totalLeaveBalance" :pageSize="sizePerPage" @onPageChange="onPageChange" />
        </div>
      </div>
      <div class="text-center mt-10" v-else>
        <img src="@/assets/images/empty-content.svg" alt="empty" class="mx-auto mt-28" />
        <div class="font-medium text-xl py-5">{{ emptyText }}</div>
      </div>
    </div>
    <Modal :modalVisible="downloadConfirmationVisible" @close="toggleDownloadConfirmation()">
      <template slot="modal-content">
        <div class="text-left my-2">
          <h1 class="text-xl font-bold mb-3">Donwload Confirmation</h1>
          <p class="text-sm mb-4">Are you sure you want to download? Please ensure this download is within the allowed limit of 250 entries only.</p>
          <div class="flex justify-end items-center gap-4">
            <Button buttonText="Cancel" type="tertiary" additionalClass="px-10" @action="toggleDownloadConfirmation()" />
            <Button buttonText="Download" type="primary" @action="downloadData()" />
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { upperCaseFirstWord } from '@/utils'
export default {
  components: {
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Search: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/Search'),
    LeaveTable: () => import(/* webpackChunkName: "simple-table" */ '@/components/Table/SimpleTable'),
    Pagination: () => import(/* webpackChunkName: "Pagination" */ '@/components/Pagination/Pagination'),
    Modal: () => import(/* webpackChunkName: "Modal" */ '@/components/Modal/Modal')
  },
  data() {
    return {
      currentPage: 1,
      sizePerPage: 10,
      downloadConfirmationVisible: false,
      stillLoading: false,
      keyword: '',
      staticColumn: [
        { label: 'Employee', field: 'employeeName' },
        { label: 'Department', field: 'departmentName' }
      ],
      dynamicColumn: [],
      leaveBalanceList: [],
      emptyText: 'Data is Not Available'
    }
  },
  computed: {
    ...mapGetters('humanresource', ['totalLeaveBalance']),
    customerId() {
      return localStorage.getItem('client')
    },
    columnsTable() {
      const joinColumn = this.staticColumn.concat(this.dynamicColumn)
      const actionColumn = {
        label: 'Actions',
        field: 'btn',
        position: 'right'
      }
      joinColumn.push(actionColumn)
      return joinColumn
    }
  },
  created() {
    this.fetchingData()
  },

  methods: {
    ...mapActions('humanresource', ['GET_LEAVE_BALANCE_LIST', 'DOWNLOAD_LEAVE_BALANCE_LIST']),
    ...mapActions('common', ['showLoading', 'hideLoading']),
    fetchingData() {
      this.emptyText = this.keyword.length === 0 ? 'Data is Not Available' : `No result with keyword ${this.keyword}`
      this.stillLoading = true
      const params = {
        q: this.keyword,
        customerId: this.customerId,
        direction: 'DESC',
        limit: this.sizePerPage,
        page: this.currentPage - 1,
        sortBy: 'name'
      }

      this.GET_LEAVE_BALANCE_LIST({ params }).then(async (response) => {
        if (response?.code === 200) {
          this.dynamicColumn = response?.data.length ? await this.getDynamicColumns(response?.data[0].leaveBalanceList) : []
          this.leaveBalanceList = response?.data.length ? await this.loopingData(response?.data) : []
        }
        this.stillLoading = false
      })
    },

    loopingData(data) {
      return new Promise((resolve) => {
        data.map(async (item) => {
          const leaveBalanceList = await this.setDynamicKeyValue(item.leaveBalanceList)
          leaveBalanceList.forEach((itemLeaveBalance) => {
            const getKeyName = Object.keys(itemLeaveBalance)[0]
            const getValue = Object.values(itemLeaveBalance)[0]
            item[getKeyName] = getValue
          })
          item.departmentName = upperCaseFirstWord(item.departmentName)
          return {
            ...item
          }
        })
        resolve(data)
      })
    },

    setDynamicKeyValue(data) {
      return new Promise((resolve) => {
        if (data.length) {
          const result = data.map((item, idx) => {
            return {
              [`employee_balance_${this.convertToSnakeCase(item.leave_type_name)}_${idx}`]: item.duration_type === 'UNLIMITED' ? 'Unlimited' : item.employee_balance
            }
          })
          resolve(result)
        } else {
          resolve([])
        }
      })
    },

    getDynamicColumns(data) {
      return new Promise((resolve) => {
        if (data.length) {
          const result = data.map((item, idx) => ({
            label: `${item.leave_type_name} (${item.unit.toLowerCase()})`,
            field: `employee_balance_${this.convertToSnakeCase(item.leave_type_name)}_${idx}`
          }))
          resolve(result)
        } else {
          resolve([])
        }
      })
    },

    convertToSnakeCase(str) {
      return str.replace(/\s+/g, '_').toLowerCase()
    },

    onPageChange(page) {
      this.currentPage = page
      this.fetchingData()
    },
    toLeaveBalanceDetail(employeeId) {
      this.$router.push(`/leave-balance/${employeeId}`)
    },
    doSearch() {
      this.currentPage = 1
      if (this.stillLoading) return
      this.fetchingData()
    },
    downloadData() {
      this.DOWNLOAD_LEAVE_BALANCE_LIST({ customerId: this.customerId }).then((response) => {
        const url = URL.createObjectURL(response.data)
        const a = document.createElement('a')
        a.download = 'Leave Balance.xlsx'
        a.href = url
        a.target = '_self'
        a.click()
        setTimeout(function () {
          a.remove()
          URL.revokeObjectURL(url)
        }, 100)
      })
    },
    toggleDownloadConfirmation() {
      this.downloadConfirmationVisible = !this.downloadConfirmationVisible
    }
  }
}
</script>

<style lang="scss" scoped>
.content-body {
  min-height: calc(83vh - 0px);
}
</style>